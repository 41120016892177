.global-filters-row {
    border-bottom: $border;
    background-color: $nav-light;
    @media (prefers-color-scheme: dark) {
        background-color: $nav-dark;
    }
    height: 5rem;
    margin-bottom: 2rem;
}

.global-filters {
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: space-evenly;

    .radio {
        position: relative;
        cursor: pointer;
        line-height: 20px;
        margin: 15px;
        &:hover {
            .label-body:after {
                transform: scale(3);
                background: $accent-light;
                @media (prefers-color-scheme: dark) {
                    background: $accent-dark;
                }
            }
            .label-body,
            input[type="radio"]:checked + .label-body {
                border-color: $accent-light;
                @media (prefers-color-scheme: dark) {
                    border-color: $accent-dark;
                }
            }
        }
    }
    .label-body {
        position: relative;
        display: block;
        float: left;
        margin-right: 1em;
        width: 16px;
        height: 16px;
        border: 1.8px solid $bg-dark;
        @media (prefers-color-scheme: dark) {
            border-color: $bg-light;
        }
        border-radius: 100%;
        -webkit-tap-highlight-color: transparent;
        &:hover {
            border-color: $accent-light;
            @media (prefers-color-scheme: dark) {
                border-color: $accent-dark;
            }
            &:after {
                background: $accent-light;
                @media (prefers-color-scheme: dark) {
                    background: $accent-dark;
                }
            }
        }
        &:after {
            content: "";
            position: absolute;
            top: 3px;
            left: 2.5px;
            width: 7px;
            height: 7px;
            border-radius: 100%;
            background: $primary-color;
            @media (prefers-color-scheme: dark) {
                background: $primary-color-dark;
            }
            transform: scale(0);
            transition: all 0.2s ease;
            opacity: 0.08;
            pointer-events: none;
        }
    }

    input[type="radio"]:checked + .label-body {
        border-color: $primary-color;
        @media (prefers-color-scheme: dark) {
            border-color: $primary-color-dark;
        }
        &:hover {
            border-color: $accent-light;
            @media (prefers-color-scheme: dark) {
                border-color: $accent-dark;
            }
        }
        &:after {
            transform: scale(1);
            transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
            opacity: 1;
        }
    }

    .hidden {
        display: none;
    }
}

.close-button {
    z-index: 100;
    right: 10px;
    top: 0;
    position: absolute;
    line-height: 1;

    transition: color 0.1s, background-color 0.1s;
    cursor: pointer;

    &:hover {
        color: $accent-light;
        @media (prefers-color-scheme: dark) {
            color: $accent-dark;
        }
    }

    &.closed {
        position: fixed;
        left: -1px;
        top: 20%;
        width: 30px;
        height: 30px;
        border: $border;
        border-radius: 0 20% 20% 0;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
        background-color: $nav-light;
        @media (prefers-color-scheme: dark) {
            background-color: $nav-dark;
        }
        &:hover {
            box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.75);
        }
    }
}

.width-transition {
    transition: max-width 0.6s;
}

@keyframes slidein {
    from {
        margin-left: 0px;
    }

    to {
        margin-left: -200px;
    }
}

.col-closed {
    display: none;
    transition: slidein 1s;
}

.filters-col {
    height: 100%;
}

.input-filters-col {
    display: flex;
    flex-direction: column;
    position: initial;
    top: initial;
    @include media-breakpoint-up(sm) {
        position: sticky;
        top: 65px;
    }

    div.attribution-radios {
        display: flex;
        flex-direction: column;
        label {
            align-self: flex-start;
        }
        input[type="radio"] {
            margin-bottom: 0px;
            margin-right: 10px;
            vertical-align: baseline;
        }
    }
}

.all-filters {
    background-color: $nav-light;
    @media (prefers-color-scheme: dark) {
        background-color: $nav-dark;
    }
    margin-top: 60px;
    @include media-breakpoint-down(sm) {
        position: initial;
        border-bottom: $border;
    }
}
