.container {
    max-width: 800px;
}

$nav-bar-height: 6rem;

/* Navbar */
.navbar {
    display: block;
    width: 100%;
    height: $nav-bar-height;
    background: $nav-light;
    z-index: 102;
    border-bottom: $border;
    @media (prefers-color-scheme: dark) {
        background: $nav-dark;
    }
    + .docs-section {
        border-top-width: 0;
    }
    > .container {
        width: 100%;
    }
}

.App {
    margin-top: 6rem;
}

.navbar-list {
    list-style: none;
    margin-bottom: 0;
    margin-left: 30px;
}
.navbar-item {
    position: relative;
    float: left;
    margin-bottom: 0;
    &.right {
        float: right;
    }
    &.icon.upload {
        margin-top: 6px;
    }
    &.icon.download {
        margin-top: 4px;
    }
    &.icon.logout {
        margin-top: 5px;
    }
}
.navbar-link {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.2rem;
    margin-right: 35px;
    @media (#{$bp-larger-than-mobile}) {
        margin-right: 30px;
    }

    @media (#{$bp-larger-than-phablet}) {
        margin-right: 35px;
    }
    text-decoration: none;
    line-height: 6rem;
    color: $text-light;
    @media (prefers-color-scheme: dark) {
        color: $text-dark;
    }
    &.active {
        color: $accent-light;
        @media (prefers-color-scheme: dark) {
            color: $accent-dark;
        }
    }
    img {
      height:$nav-bar-height*0.9;
    }
}

.has-docked-nav {
    .navbar {
        position: fixed;
        top: 0;
        left: 0;
        > .container {
            width: 80%;
        }
    }
    .navbar-spacer {
        display: block;
    }
}
