.spinner {
    margin: 100px auto 0;
    width: 70px;
    text-align: center;
    position: fixed;
    z-index: 999;
    overflow: show;
    top: 30%;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */

.spinner:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($bg-light, 0.7);
    @media (prefers-color-scheme: dark) {
        background-color: rgba($bg-dark, 0.7);
    }
}

.spinner > div {
    width: 18px;
    height: 18px;
    background-color: $accent-light;
    @media (prefers-color-scheme: dark) {
        background-color: $accent-dark;
    }
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
