.d3-tip {
    line-height: 1;
    padding: 12px;
    max-width: 150px;
    background: $tooltip-bg-light;
    color: $tooltip-text-light;
    @media (prefers-color-scheme: dark) {
        background: $tooltip-bg-dark;
        color: $tooltip-text-dark;
    }
    border-radius: 2px;
    pointer-events: none;
    transition: 0.1s;
    text-align: center;
    z-index: 9999;
    table,
    tr,
    th,
    td {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: initial;
    }
}

/* Creates a small triangle extender for the tooltip */

.d3-tip:after {
    box-sizing: border-box;
    display: inline;
    font-size: 10px;
    width: 100%;
    line-height: 1;
    color: $tooltip-bg-light;
    @media (prefers-color-scheme: dark) {
        color: $tooltip-bg-dark;
    }
    position: absolute;
    pointer-events: none;
}

/* Northward tooltips */

.d3-tip.n:after {
    content: "\25BC";
    margin: -1px 0 0 0;
    top: 100%;
    left: 0;
    text-align: center;
}

/* Eastward tooltips */

.d3-tip.e:after {
    content: "\25C0";
    margin: -4px 0 0 0;
    top: 50%;
    left: -8px;
}

/* Southward tooltips */

.d3-tip.s:after {
    content: "\25B2";
    margin: 0 0 1px 0;
    top: -8px;
    left: 0;
    text-align: center;
}

/* Westward tooltips */

.d3-tip.w:after {
    content: "\25B6";
    margin: -4px 0 0 -1px;
    top: 50%;
    left: 100%;
}
