// Lists
//––––––––––––––––––––––––––––––––––––––––––––––––––

ul {
  list-style: circle inside;
}

ol {
  list-style: decimal inside;
  padding-left: 0;
  margin-top: 0;
}

ul {
  padding-left: 0;
  margin-top: 0;
  ul, ol {
    margin: 1.5rem 0 1.5rem 3rem;
    font-size: 90%;
  }
}

ol {
  ol, ul {
    margin: 1.5rem 0 1.5rem 3rem;
    font-size: 90%;
  }
}

li {
  margin-bottom: 1rem;
}
