$bg-dark: #242129;
$bg-light: hsl(263, 12%, 95%);
$nav-dark: lighten($bg-dark, 10%);
$nav-light: lighten($bg-light, 10%);
$accent: #ffc0b8;
$accent-dark: darken($accent, 10%);
$accent-light: darken($accent, 20%);
$tooltip-bg-dark: $bg-light;
$tooltip-bg-light: $bg-dark;
$tooltip-text-dark: $bg-dark;
$tooltip-text-light: $bg-light;
$text-light: $bg-dark;
$text-dark: $bg-light;

// Colors skeleton
$light-grey: $bg-light !default;
$dark-grey: $bg-dark !default;
$primary-color: #08306b !default;
$primary-color-dark: lighten($primary-color, 40%) !default;
$secondary-color: lighten($dark-grey, 13.5%) !default;
$border-color: #7b7485 !default;
$border: 0.6px solid $border-color;
$link-color: lighten($primary-color, 30%) !default;
$font-color: #222 !default;
$error-color: #e00000;
$success-color: hsla(135, 65%, 52%, 0.726);

@import "_tables";
@import "_tip";
@import "_spinner";
@import "_skeleton";
@import "_charts";
@import "_navbar";
@import "_buttons";
@import "./bootstrap/scss/bootstrap-grid.scss";
@import "./bootstrap/scss/mixins";
@import "_filterscolumns";

$progress-height: 24px;
$progress-bg: $success-color;
$progress-border-radius: $global-radius;
$progress-box-shadow: None;
$progress-bar-color: darken($success-color, 10%);
$progress-bar-bg: $success-color;
$progress-bar-animation-timing: 0.4s;

.progress-bar {
    padding: 3px;
    border: 1px $success-color solid;
    border-radius: $global-radius;
    margin-bottom: 20px;
    &.error {
        color: white;
        background-color: rgba($error-color, 0.7);
        border: 1px $error-color solid;
    }
}

@import "./bootstrap/scss/progress";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    font-size: 1.2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $bg-light;
    @media (prefers-color-scheme: dark) {
        background-color: $bg-dark;
        color: $text-dark;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.app {
    .all-filters,
    .tables-row > .col {
        padding-top: 1em;
    }
    .portfolio {
        padding-top: 30px;
    }
}

.svg-container {
    display: inline-block;
    position: relative;
    width: 100%;
    // padding-bottom: 100%;
    // vertical-align: middle;
    overflow: hidden;
}

.svg-content {
    display: inline-block;
    // position: absolute;
    top: 0;
    left: 0;
}

.country-flag {
    max-height: 40px;
    width: auto;
    margin: 15px 30px;
}

.text-center {
    text-align: center !important;
}

h5 {
    margin-bottom: 1rem;
}

a {
    &:hover {
        @media (prefers-color-scheme: dark) {
            color: $primary-color-dark;
        }
    }
}

.overflow-x-auto {
    overflow-x: auto;
}

.no-pa {
    padding: 0;
}
.padding-top-20 {
    padding-top: 20px;
}

.logos-row {
    padding-top: 50px;
    img {
        max-width: 100px;
    }
    .col {
        align-self: center;
    }
}

div.email {
    padding-top: 60px;
    @media (max-width: 991.98px) {
        padding-top: 0px;
    }
}

.home-page {
    padding-top: 70px;
}
footer {
    border-top: $border;
    margin-left: -15px;
    margin-right: -15px;
    text-align: center;
    background-color: $nav-light;
    @media (prefers-color-scheme: dark) {
        background-color: $nav-dark;
    }
}
.webinar {
    width: 100%;
    overflow: hidden;
}

.update-notification {
    z-index: 999;
    position: fixed;
    top: 0;
    right: 0;
    padding: 15px;
    border-radius: $global-radius;
    background-color: $accent-dark;
    color: $text-light;
    @media (prefers-color-scheme: dark) {
        background-color: $accent-dark;
        color: $text-light;
        a {
            color: $bg-dark;
        }
    }
    a {
        color: $bg-light;
        &:hover {
            cursor: pointer;
        }
    }
}

.expandable {
    color: $primary-color;
    &:hover {
        color: $accent-light;
        cursor: pointer;
        @media (prefers-color-scheme: dark) {
            color:$accent-dark;
        }
    }    
    @media (prefers-color-scheme: dark) {
        color: $primary-color-dark;
    }
}
