$table_padding: 7px 15px;

.tables-row {
    border-bottom: $border;
    margin-bottom: 1em;
    padding-bottom: 20px;
    padding-top: 20px;
    &.portfolio {
        margin-bottom: 0;
    }

    table {
        margin-bottom: 1rem;
    }

    tbody,
    thead {
        th,
        td {
            padding: $table_padding;
            border-top: 1px solid $border-color;
            border-bottom: none;
        }
    }
    svg {
        margin-right: 10px;
        width: 15px;
        height: 15px;
    }
    .resultsDetail {
        th, 
        td {
            border: none;
        }
    }
    .aligned-r {
        &.conf-level {
            td:nth-child(even) {
                padding-right: 5px;
            }
            td:nth-child(odd) {
                padding: 0;
                text-align: left;
            }
        }
        tbody {
            td {
                text-align: right;
            }
        }

        thead {
            th {
                text-align: right;
            }
        }
        &.conf-level {
            thead {
                th {
                    padding-right: 0;
                }
            }
        }
    }
}

.table-footer {    
    padding: $table_padding;
    font-size: 80%;
}
