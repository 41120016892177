.button,
button,
input[type="button"] {
    &:disabled {
        background-color: $light-grey;
        color: $secondary-color;
        cursor: not-allowed;
        pointer-events: none;
    }
    &.secondary:hover {
        border-color: $link-color;
    }
    &.link {
        border: initial;
        color: $accent-light;
        @media (prefers-color-scheme: dark) {
            color: $accent-dark;
        }
    }
}

.button-fixed {
    width: 200px;
    text-align: left;
}

select {
    @media (prefers-color-scheme: dark) {
        background-color: $bg-dark;
    }
}

.multi-select {
    height: initial;
}

.input-label-group {
    align-items: baseline;
}

input {
    &.error {
        border: 1px solid $error-color;
        outline: 0;
    }
    &[type="radio"] {
        margin-bottom: 0.5em;
    }
}

label {
    &.error {
        color: $error-color;
        margin-bottom: 0;
    }
}

small {
    &.error {
        color: $error-color;
    }
    list-style-position: inside;
}

div {
    &.box {
        border-radius: $global-radius;
        padding: 3px;
        text-align: center;
        margin-bottom: 1rem;
        &.error {
            border: 1px $error-color solid;
            color: $error-color;
            background-color: rgba($error-color, 0.1);
        }
        &.success {
            border: 1px $success-color solid;
            color: $success-color;
            background-color: rgba($success-color, 0.1);
        }
    }
}

.button-svg-dl {
    font-size: 8px;
    padding: 1px;
    height: initial;
    line-height: initial;
}
