.bar,
.country,
.arc,
.tree-node {
    transition: fill 0.2s;
    &:hover {
        fill: rgba($color: $accent-light, $alpha: 0.9) !important;
        @media (prefers-color-scheme: dark) {
            fill: rgba($color: $accent-dark, $alpha: 0.9) !important;
        }
        cursor: pointer;
    }
}
.polygons {
    &:hover {
        cursor: pointer;
    }
}
.arc {
    transition: stroke 0.1s;
    &:hover {
        stroke: rgba($color: $accent-light, $alpha: 0.9) !important;
        @media (prefers-color-scheme: dark) {
            stroke: rgba($color: $accent-dark, $alpha: 0.9) !important;
        }
    }
}

.countries {
    transform: translateY(40px);
    fill: $accent-light;
    @media (prefers-color-scheme: dark) {
        fill: $accent-dark;
    }
}

.legend-box {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 0;
    .legend {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .color {
            width: 1em;
            height: 1em;
            align-self: center;
        }
        p {
            align-self: center;
            margin-bottom: 0;
            margin-left: 1em;
            font-size: 0.8em;
        }
    }
}

tspan {
    pointer-events: none;
}

.border-r {
    border-right: $border;
}

.input-data-table {
    border-radius: $global-radius;
    background-color: darken($bg-light, 10%);
    @media (prefers-color-scheme: dark) {
        background-color: lighten($bg-dark, 10%);
    }
}

.title-bar {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    border-bottom: $border;
    margin-bottom: 10px;
    .download-icon {
        &:hover {
            cursor: pointer;
        }
    }
}

text {
    &.world-map {
        font-size: 15px;
    }
}
